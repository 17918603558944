'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var httpRpcWireFormat = require('../lib/http-rpc-wire-format.js');

const createLs = configure.configure(api => {
  async function ls(options = {}) {
    const {Strings} = await (await api.post('pubsub/ls', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    })).json();
    return httpRpcWireFormat.rpcArrayToTextArray(Strings) || [];
  }
  return ls;
});

exports.createLs = createLs;
