'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var abortSignal = require('../lib/abort-signal.js');

const createPut = configure.configure(api => {
  async function put(data, options = {}) {
    const controller = new AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    let res;
    try {
      const response = await api.post('block/put', {
        signal: signal,
        searchParams: toUrlSearchParams.toUrlSearchParams(options),
        ...await multipartRequest.multipartRequest([data], controller, options.headers)
      });
      res = await response.json();
    } catch (err) {
      if (options.format === 'dag-pb') {
        return put(data, {
          ...options,
          format: 'protobuf'
        });
      } else if (options.format === 'dag-cbor') {
        return put(data, {
          ...options,
          format: 'cbor'
        });
      }
      throw err;
    }
    return cid.CID.parse(res.Key);
  }
  return put;
});

exports.createPut = createPut;
