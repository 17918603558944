'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var fromString = require('uint8arrays/from-string');
var responseTypes = require('./response-types.js');
var multiaddr = require('multiaddr');

const mapEvent = event => {
  if (event.Type === responseTypes.SendingQuery) {
    return {
      to: event.ID,
      name: 'SENDING_QUERY',
      type: event.Type
    };
  }
  if (event.Type === responseTypes.PeerResponse) {
    return {
      from: event.ID,
      name: 'PEER_RESPONSE',
      type: event.Type,
      messageType: 0,
      messageName: 'PUT_VALUE',
      closer: (event.Responses || []).map(({ID, Addrs}) => ({
        id: ID,
        multiaddrs: Addrs.map(addr => new multiaddr.Multiaddr(addr))
      })),
      providers: (event.Responses || []).map(({ID, Addrs}) => ({
        id: ID,
        multiaddrs: Addrs.map(addr => new multiaddr.Multiaddr(addr))
      }))
    };
  }
  if (event.Type === responseTypes.FinalPeer) {
    let peer = {
      id: event.ID,
      multiaddrs: []
    };
    if (event.Responses && event.Responses.length) {
      peer = {
        id: event.Responses[0].ID,
        multiaddrs: event.Responses[0].Addrs.map(addr => new multiaddr.Multiaddr(addr))
      };
    }
    return {
      from: event.ID,
      name: 'FINAL_PEER',
      type: event.Type,
      peer
    };
  }
  if (event.Type === responseTypes.QueryError) {
    return {
      from: event.ID,
      name: 'QUERY_ERROR',
      type: event.Type,
      error: new Error(event.Extra)
    };
  }
  if (event.Type === responseTypes.Provider) {
    return {
      from: event.ID,
      name: 'PROVIDER',
      type: event.Type,
      providers: event.Responses.map(({ID, Addrs}) => ({
        id: ID,
        multiaddrs: Addrs.map(addr => new multiaddr.Multiaddr(addr))
      }))
    };
  }
  if (event.Type === responseTypes.Value) {
    return {
      from: event.ID,
      name: 'VALUE',
      type: event.Type,
      value: fromString.fromString(event.Extra, 'base64pad')
    };
  }
  if (event.Type === responseTypes.AddingPeer) {
    const peers = event.Responses.map(({ID}) => ID);
    if (!peers.length) {
      throw new Error('No peer found');
    }
    return {
      name: 'ADDING_PEER',
      type: event.Type,
      peer: peers[0]
    };
  }
  if (event.Type === responseTypes.DialingPeer) {
    return {
      name: 'DIALING_PEER',
      type: event.Type,
      peer: event.ID
    };
  }
  throw new Error('Unknown DHT event type');
};

exports.mapEvent = mapEvent;
