'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var fromString = require('uint8arrays/from-string');
var toString = require('uint8arrays/to-string');
var base64 = require('multiformats/bases/base64');

const rpcArrayToTextArray = strings => {
  if (Array.isArray(strings)) {
    return strings.map(rpcToText);
  }
  return strings;
};
const rpcToText = mb => toString.toString(rpcToBytes(mb));
const rpcToBytes = mb => base64.base64url.decode(mb);
const textToUrlSafeRpc = text => base64.base64url.encode(fromString.fromString(text));

exports.rpcArrayToTextArray = rpcArrayToTextArray;
exports.rpcToBytes = rpcToBytes;
exports.rpcToText = rpcToText;
exports.textToUrlSafeRpc = textToUrlSafeRpc;
