'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var mapEvent = require('./map-event.js');
var toString = require('uint8arrays/to-string');

const createGet = configure.configure(api => {
  async function* get(key, options = {}) {
    const res = await api.post('dht/get', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: key instanceof Uint8Array ? toString.toString(key) : key.toString(),
        ...options
      }),
      headers: options.headers
    });
    for await (const event of res.ndjson()) {
      yield mapEvent.mapEvent(event);
    }
  }
  return get;
});

exports.createGet = createGet;
