'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var fromString = require('uint8arrays/from-string');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var abortSignal = require('../lib/abort-signal.js');

const createReplace = configure.configure(api => {
  const replace = async (config, options = {}) => {
    const controller = new AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const res = await api.post('config/replace', {
      signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      ...await multipartRequest.multipartRequest([fromString.fromString(JSON.stringify(config))], controller, options.headers)
    });
    await res.text();
  };
  return replace;
});

exports.createReplace = createReplace;
