"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Algorithm;
(function (Algorithm) {
    Algorithm["sha1"] = "SHA-1";
    Algorithm["sha256"] = "SHA-256";
    Algorithm["sha384"] = "SHA-384";
    Algorithm["sha512"] = "SHA-512";
})(Algorithm || (Algorithm = {}));
function hashBlob(algorithm, blob, encoding) {
    if (encoding === void 0) { encoding = 'hex'; }
    return new Promise(function (resolve, reject) {
        var fileReader = new FileReader();
        fileReader.addEventListener('load', function () {
            crypto.subtle.digest(Algorithm[algorithm], fileReader.result).then(function (buffer) {
                var typedArray = new Uint8Array(buffer);
                if (encoding === 'hex') {
                    resolve(Array.prototype.map.call(typedArray, function (x) { return ('00' + x.toString(16)).slice(-2); }).join(''));
                }
                else {
                    resolve(btoa(String.fromCharCode.apply(null, typedArray)));
                }
            });
        });
        fileReader.addEventListener('error', function () {
            reject(fileReader.error);
        });
        fileReader.readAsArrayBuffer(blob);
    });
}
exports.default = hashBlob;
