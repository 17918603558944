'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var configure = require('../../lib/configure.js');
var toUrlSearchParams = require('../../lib/to-url-search-params.js');
var abortSignal = require('../../lib/abort-signal.js');

const createSetData = configure.configure(api => {
  async function setData(cid$1, data, options = {}) {
    const controller = new AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const res = await api.post('object/patch/set-data', {
      signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: [`${ cid$1 }`],
        ...options
      }),
      ...await multipartRequest.multipartRequest([data], controller, options.headers)
    });
    const {Hash} = await res.json();
    return cid.CID.parse(Hash);
  }
  return setData;
});

exports.createSetData = createSetData;
